<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>检修记录</span>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="order" label="检修代号"></el-table-column>
          <el-table-column prop="alias" label="设备别名"></el-table-column>
          <el-table-column prop="deviceName" label="设备名称"></el-table-column>
          <el-table-column prop="specification" label="设备参数"></el-table-column>
          <el-table-column prop="deviceTypeName" label="设备类型"></el-table-column>
          <el-table-column prop="patrolName" label="巡视点名称"></el-table-column>
          <el-table-column prop="facilityName" label="设施位置"></el-table-column>
          <el-table-column label="来源">
            <template slot-scope="scope">
              {{ scope.row.source==0 ? '新建' : scope.row.source==1 ? '巡检' : '点检' }}
            </template>
          </el-table-column>
          <el-table-column label="检修人">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.staffNames" :key="index">
                {{ scope.row.staffNames.length-1 > index ? item+'、' : item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="检修结果">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.status==2">未解决</el-tag>
              <el-tag type="success" v-if="scope.row.status==4">已解决</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="结束时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.updateTime ? dayjs(scope.row.updateTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      staffId: 0
    }
  },
  methods: {
    dayjs,
    details(row) {
      this.$router.push({ name: 'overhaulRecordDetail', params: {row: row}})
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('reconditionRecord', {
        pageNum: this.page,
        pageSize: this.size,
      }).then(res => {
        this.loading = false
        this.tableData = res.data.filter((r)=>{
          return r.status == 2 || r.status == 4
        })
        this.total = this.tableData.length
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
</style>